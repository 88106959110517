<template>
  <div>
    <RevContentBlock
      :button-label="buttonLabel"
      :button-variant="buttonVariant"
      :class="[dynamicClasses, styles.fixedWidthButton]"
      :has-rounded-container="!hasRoundedImage"
      :has-rounded-image="hasRoundedImage"
      :image-left="isContentRight"
      :image-props="image"
      :rel="ctaLink?.link?.rel"
      :surtitle="surtitle"
      :target="ctaLink?.link?.target"
      :title="contentTitle"
      title-variant="title2"
      :to="ctaLink?.link?.href"
      @click="handleCtaLinkClick"
    >
      <div>
        <span class="whitespace-pre-line">{{ text }}</span>
      </div>
    </RevContentBlock>

    <YoutubeVideoModal
      v-if="ctaVideo"
      :name="videoModalName"
      :title="ctaVideo.title"
      :youtube-id="ctaVideo.id"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, useCssModule } from 'vue'

import type { ContentMediaProps } from '@backmarket/http-api/src/api-specs-content/models/content-media'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { tw } from '@backmarket/utils/string/tw'
import { RevContentBlock } from '@ds/components/ContentBlock'
import { openModal } from '@ds/components/ModalBase'

import YoutubeVideoModal from '../../shared-components/YoutubeVideoModal/YoutubeVideoModal.vue'

import { mapToNewDsClass } from './utils'

export type ContentMediaBlockProps = ContentMediaProps & ContentBlockProps

const props = withDefaults(defineProps<ContentMediaBlockProps>(), {
  tracking: () => ({}),
  title: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackClick } = useTracking()
const hasCTALink = computed(() => !isEmpty(props.ctaLink))
const buttonVariant = computed(() =>
  props.enableDarkMod ? 'primary' : 'secondary',
)

const videoModalName = computed(() => `${props.ctaVideo?.id}-video-modal`)
const buttonLabel = computed(() =>
  hasCTALink.value ? props.ctaLink?.label : props.ctaVideo?.label,
)

const isContentRight = computed(() => props.textPosition === 'right')

const desktopPaddingClass = computed(() => {
  const hasBackgroundColor = props.backgroundColor
  if (hasBackgroundColor) return tw`md:py-0 md:px-24`

  return isContentRight.value ? tw`md:p-0 md:pr-24` : tw`md:p-0 md:pl-24`
})
const dynamicClasses = computed(() => {
  const darkTheme = props.enableDarkMod ? tw`mood-inverse` : ''
  const mobilePadding = props.backgroundColor ? tw`p-24` : ''
  const desktopPadding = desktopPaddingClass.value

  const backgroundClass = mapToNewDsClass(props.backgroundColor)

  return `${backgroundClass} ${darkTheme} ${mobilePadding} ${desktopPadding}`
})
const hasRoundedImage = computed(() => !props.backgroundColor)

const hasCtaVideo = computed(() => !isEmpty(props.ctaVideo))

const styles = useCssModule()
function sendTrackingData() {
  trackClick({
    ...props.tracking,
    name: props.contentTitle,
  })
}

function openVideoModal() {
  openModal(videoModalName.value)
}

function handleCtaLinkClick() {
  sendTrackingData()
  if (hasCtaVideo.value) openVideoModal()
}
</script>

<style module>
.fixedWidthButton {
  @media (min-width: theme('screens.md')) {
    button {
      width: 360px;
    }

    a {
      width: 360px;
    }
  }
}
</style>
