import type { ContentMediaBackgroundClass } from '@backmarket/http-api/src/api-specs-content/models/content-media'
import { tw } from '@backmarket/utils/string/tw'

const oldToNewBackgroundClass: Record<ContentMediaBackgroundClass, string> = {
  'bg-primary': tw`bg-action-default-hi`,
  'bg-secondary': tw`bg-float-default-low`,
  'bg-info': tw`bg-static-info-hi`,
  'bg-info-light': tw`bg-static-info-mid`,
  'bg-accent': tw`mood-purple bg-static-default-low`,
  'bg-accent-light': tw`bg-static-info-max`,
  'bg-warning': tw`bg-static-warning-mid`,
  'bg-warning-light': tw`bg-static-warning-low`,
  'bg-paper-primary': tw`bg-surface-default-mid`,
}
export function mapToNewDsClass(
  oldBackgroundClass?: ContentMediaBackgroundClass,
): string {
  return oldBackgroundClass ? oldToNewBackgroundClass[oldBackgroundClass] : ''
}
